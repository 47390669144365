import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';
import Quill from 'quill';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import { ImageExtend } from 'quill-image-extend-module';
import ImageResize from 'quill-image-resize-vue';


Quill.register('modules/emoji', Emoji);
Quill.register('modules/ImageExtend', ImageExtend);
Quill.register('modules/imageResize', ImageResize);

Vue.use(VueQuillEditor, {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'emoji', 'link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet'[{ emoji: function() {} }] }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: ['', 'center', 'right'] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }, { size: [] }]
        ],
        imageResize: {
            displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
            },
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true
    },
    placeholder: 'Saisissez votre texte formatté ...'
});
