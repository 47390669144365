import Repository from './Repository.js';

class DocumentsRepository extends Repository {

    async downloadDocuments(body) {
        return await this.download('documents/download', body);
    }

}

export default new DocumentsRepository();
