import Vue from 'vue';

import sot from './repositories/sot.js';
import auth from './repositories/auth.js';
import debug from './repositories/debug.js';
import sales from './repositories/sales.js';
import users from './repositories/users.js';
import contacts from './repositories/contacts.js';
import partners from './repositories/partners.js';
import programs from './repositories/programs.js';
import dashboard from './repositories/dashboard.js';
import messaging from './repositories/messaging.js';
import providers from './repositories/providers.js';
import parameters from './repositories/parameters.js';
import actualities from './repositories/actualities.js';
import documents from './repositories/documents.js';
import appointments from './repositories/appointments.js';
import programPartnerGroups from './repositories/programPartnerGroups.js';

import getColorByName from './helpers/getColorByName.js';

Vue.mixin({
    data: () => ({
        repos: {
            sot,
            auth,
            debug,
            sales,
            users,
            contacts,
            partners,
            programs,
            dashboard,
            messaging,
            providers,
            parameters,
            actualities,
            documents,
            appointments,
            programPartnerGroups
        }
    }),

    computed: {
        selectedSale() {
            return this.$store.state.application.selectedSale;
        },

        isBuyer() {
            return this.$store.state.application.user && this.$store.state.application.user.type === 'buyer';
        },

        isUser() {
            return this.$store.state.application.user && this.$store.state.application.user.type === 'user';
        },

        isSeller() {
            return this.isUser && this.$store.state.application.user.targetType === 2;
        }
    },

    methods: {
        getColorByName(name) {
            return getColorByName(name);
        },

        setLoading(loading) {
            this.$store.commit('application/setLoading', loading);
        },

        getConfig(path, defaultValue) {
            if (!this.$store.state.application.config) {
                return defaultValue;
            } else {
                if (path === '*') {
                    return this.$store.state.application.config;
                } else {
                    return path.split('.').reduce((a, b) => a[b], this.$store.state.application.config);
                }
            }
        },

        getParameter(code) {
            return this.$store.getters['parameters/getParameter'](code);
        },

        getParameterValue(code, value) {
            return this.$store.getters['parameters/getParameterValue'](code, value);
        }
    }
});